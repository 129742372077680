import React from "react";
import "./footer.css";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";
import { ReactComponent as LogoBorderBlack } from "../../media/logo/logo-single-black-border.svg";
import { ReactComponent as LogoBorderWhite } from "../../media/logo/logo-single-white-border.svg";
import { useTheme } from "@emotion/react";
import { selectActiveTheme } from "../../store/reducers/userSlice";

function Footer() {
	const screenSize = useSelector(selectScreenSize);
	const theme = useTheme();
	const themeMode = useSelector (selectActiveTheme);

	const email = "emre@artyxcreative.com"
	const phoneNumber ="+ 381 69 5250012"
	return (
		<div
			className="footer-container"
			style={{ height: isMobile || screenSize === "small" ? "200px" : "250px" }}
		>
			<div className="footer-logo">
				{themeMode === "light" ? (
					<LogoBorderBlack className="overlay-logo" />
				) : (
					<LogoBorderWhite className="overlay-logo" />
				)}
			</div>
			<h4 className="footer-heading" style={{color: theme.palette.text.primary,}}>
				Contact | Socials
			</h4>
			<p  style={{color: theme.palette.text.primary,}}>{email}</p>
			<p  style={{color: theme.palette.text.primary,}}>{phoneNumber}</p>
		</div>
	);
}

export default Footer;
