import "../header/header.css";
import { useDispatch, useSelector } from "react-redux";
import { THEME_MODES, setThemeMode } from "../../store/reducers/userSlice";
import { ReactComponent as LightIcon } from "../../media/mode/light-mode.svg";
import { ReactComponent as DarkIcon } from "../../media/mode/dark-mode.svg";
import { ReactComponent as SelectedBgIcon } from "../../media/language/language-selected.svg";
import { ReactComponent as NotSelectedBgIcon } from "../../media/language/language-not-selected.svg";
import { useTranslation } from "react-i18next";

function ModeModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.user.theme);

  const LightBgIcon =
    currentTheme === THEME_MODES.LIGHT ? SelectedBgIcon : NotSelectedBgIcon;
  const DarkBgIcon =
    currentTheme === THEME_MODES.DARK ? SelectedBgIcon : NotSelectedBgIcon;

  const lightTextColorClass =
    currentTheme === THEME_MODES.LIGHT ? "selected-language-text" : "";

  const darkTextColorClass =
    currentTheme === THEME_MODES.DARK ? "selected-language-text" : "";

  const handleModeSelect = (mode) => {
    if (currentTheme !== mode) {
      dispatch(setThemeMode(mode));
    }
  };

  return (
    <div className="language-mobile-modal">
      <div
        className={`menu-mobile-links ${
          currentTheme === THEME_MODES.LIGHT ? "selected" : ""
        }`}
        onClick={() => handleModeSelect(THEME_MODES.LIGHT)}
      >
        <div className={`menu-mobile-links-option-font ${lightTextColorClass}`}>
          {t("lightMode")}
        </div>
        <div className="language-mobile-selected">
          <LightBgIcon className="language-desktop-selected-bg" />
          <LightIcon className="language-desktop-selected-option-icons" />
        </div>
      </div>
      <div
        className={`menu-mobile-links ${
          currentTheme === THEME_MODES.DARK ? "selected" : ""
        }`}
        onClick={() => handleModeSelect(THEME_MODES.DARK)}
      >
        <div className={`menu-mobile-links-option-font ${darkTextColorClass}`}>
          {t("darkMode")}
        </div>
        <div className="language-mobile-selected">
          <DarkBgIcon className="language-desktop-selected-bg" />
          <DarkIcon className="language-desktop-selected-option-icons" />
        </div>
      </div>
    </div>
  );
}

export default ModeModal;
