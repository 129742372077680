import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Logo from "./Logo";
import LanguageSelect from "./LanguageSelect";
import { useTranslation } from "react-i18next";

function Header() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="header-container"
      style={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
      }}
    >
      <Logo />

      <div className="menu-desktop">
        <div
          className="menu-desktop-links menu-desktop-font"
          onClick={() => navigate("/about-us")}
        >
          {t("aboutUs")}
        </div>
        <div
          className="menu-desktop-links menu-desktop-font"
          onClick={() => navigate("/services")}
        >
          {t("services")}
        </div>
        <div className="menu-desktop-font menu-desktop-language">
          {t("language")}
          &nbsp;
          <LanguageSelect />
        </div>
      </div>
    </div>
  );
}

export default Header;
