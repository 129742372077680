import "./mode.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LightIcon } from "../../media/mode/light-mode.svg";
import { ReactComponent as DarkIcon } from "../../media/mode/dark-mode.svg";
import { setThemeMode } from "../../store/reducers/userSlice";
import { useTheme } from "@emotion/react";

function ModeToggle() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.user.theme);

  const handleModeSelect = () => {
    const newMode = currentTheme === "light" ? "dark" : "light";
    dispatch(setThemeMode(newMode));
  };

  return (
    <div
      style={{ backgroundColor: theme.palette.lightblue.main }}
      className={`mode-container ${currentTheme}`}
      onClick={handleModeSelect}
    >
      <div className="mode-container-theme">
        {currentTheme === "light" ? <DarkIcon /> : <LightIcon />}
      </div>
    </div>
  );
}

export default ModeToggle;
