import "./loading-modal.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { selectDataState } from "../../store/reducers/notificationSlice";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { DATA_STATE } from "../../helpers/app.constants";

const LoadingModal = () => {
  const dataState = useSelector(selectDataState);
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  return (
    <Backdrop
      sx={{
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 500,
      }}
      open={dataState === DATA_STATE.DATA_STATE_LOADING}
    >
      <CircularProgress
        color="inherit"
        style={{
          height: isSmallScreen ? "15vh" : "20vh",
          width: isSmallScreen ? "15vh" : "20vh",
        }}
      />
    </Backdrop>
  );
};

export default LoadingModal;
