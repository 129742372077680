import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const getDesignTokens = (mode) => ({
	palette: {
		mode,
		...(mode === "light"
			? {
					primary: {
						main: "#FFFFFF",
						mainTransparent: "rgba(255, 255, 255, 0.98)",
						lighter: "rgb(245, 252, 251)",
						glass: "rgba(255, 255, 255, 0.3)",
					},
					text: {
						primary: "#000000",
						secondary: "#333",
						third: "#666",
					},
					hover: {
						main: "rgba(238, 231, 221, 0.35)",
						boxShadow: grey[600]
					},
					lightblue: {
						main: "#6ae7da",
					},
					divider: grey[400],
			  }
			: {
					primary: {
						main: "#15322C",
						mainTransparent: "rgba(21, 50, 44, 0.50)",
						lighter: "rgb(42, 99, 88)",
						glass: "rgba(21, 50, 44, 0.3)",
					},
					text: {
						primary: "#FFFFFF",  
						secondary: grey[300],
						third: grey[400],
					},
					hover: {
						main: "rgba(238, 231, 221, 0.3)",
						boxShadow: grey[500]
					},
					lightblue: {
						main: "#6ae7da",
					},
					divider: grey[400],
			  }),
	},
});

export const themeCreation = (mode) =>
	createTheme({
		...getDesignTokens(mode),
		components: {
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						backgroundColor: mode === "light" ? grey[900] : grey[50],
						color: mode === "light" ? grey[50] : grey[900],
					},
				},
			},
		},
	});
