import React from "react";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectActiveLanguage } from "../../store/reducers/userSlice";
import LanguageOption from "./LanguageOption";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { ClickAwayListener } from "@mui/material";
import { useTranslation } from "react-i18next";

function LanguageModal({ onLanguageSelect, onClose }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const currentLanguage = useSelector(selectActiveLanguage);

  const modalClassName = isSmallScreen
    ? "language-mobile-modal"
    : "language-desktop-modal";

  const languageOptions = [
    { code: "en", label: t('english') }, 
    { code: "tr", label: t('turkish') }, 
    { code: "sr", label: t('serbian') }, 
  ];

  const modalContent = (
    <div
      className={modalClassName}
      style={{
        backgroundColor: isSmallScreen
          ? undefined
          : theme.palette.primary.main,
      }}
    >
      {languageOptions.map(({ code, label }) => (
        <LanguageOption
          key={code}
          language={{ code, label }}
          onClick={() => onLanguageSelect(code)}
        />
      ))}
    </div>
  );

  return isSmallScreen ? (
    modalContent
  ) : (
    <ClickAwayListener onClickAway={onClose}>
      {modalContent}
    </ClickAwayListener>
  );
}

export default LanguageModal;