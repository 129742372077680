import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreation } from "./theme/designTokens";
import { useSelector } from "react-redux";
import { selectActiveTheme } from "./store/reducers/userSlice";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScreenSizeListener from "./helpers/other/ScreenSizeListener";
import { Route, Routes } from "react-router-dom";
import { selectScreenSize } from "./store/reducers/layoutSlice";
import LoadingModal from "./components/other/LoadingModal";
import Mainpage from "./pages/Mainpage";
import HeaderMobile from "./components/header-mobile/HeaderMobile";
import Header from "./components/header/Header";
import ModeToggle from "./components/header/ModeToggle";

function App() {
  const themeMode = useSelector(selectActiveTheme);
  const [theme, setTheme] = useState(themeCreation());
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  useEffect(() => {
    setTheme(themeCreation(themeMode));
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <ScreenSizeListener />

      {isSmallScreen ? (
        <HeaderMobile />
      ) : (
        <>
          <Header />
          <div className="mode-desktop">
            <ModeToggle />
          </div>
        </>
      )}

      <Routes>
        <Route path="/" element={<Mainpage />} />
        {/* Add more Route components here as needed */}
      </Routes>

      <LoadingModal />
    </ThemeProvider>
  );
}

export default App;
