import "./language.css";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage, LANGUAGE_OPTIONS } from "../../store/reducers/userSlice";
import { ReactComponent as LngSelected } from "../../media/language/language-selected.svg";
import { ReactComponent as EnglishIcon } from "../../media/language/english.svg";
import { ReactComponent as TurkishIcon } from "../../media/language/turkey.svg";
import { ReactComponent as SerbianIcon } from "../../media/language/serbian.svg";
import LanguageModal from "./LanguageModal";
import { useTheme } from "@emotion/react";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";

const languageIcons = {
  [LANGUAGE_OPTIONS.ENGLISH]: EnglishIcon,
  [LANGUAGE_OPTIONS.TURKISH]: TurkishIcon,
  [LANGUAGE_OPTIONS.SERBIAN]: SerbianIcon,
};

function LanguageSelect() {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.user.language);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const modalRef = useRef(null);
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  const handleLanguageChange = (language) => {
    dispatch(setLanguage({ language }));
    setIsModalOpen(false);
    setIsRotating(true);
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isRotating) {
      const timer = setTimeout(() => setIsRotating(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isRotating]);

  const CurrentLanguageIcon = languageIcons[currentLanguage] || EnglishIcon;

  return (
    <div className="language-desktop-select">
      <div className="language-desktop-selected" onClick={toggleModal}>
        <LngSelected
          style={{ width: isSmallScreen ? "" : "49px", height: "48px" }}
        />
        <CurrentLanguageIcon
          className={`language-desktop-selected-icon ${
            isRotating ? "rotate" : ""
          }`}
        />
      </div>
      {isModalOpen && (
        <div ref={modalRef} className="modal-container">
          <LanguageModal
            onLanguageSelect={handleLanguageChange}
            CurrentLanguageIcon={CurrentLanguageIcon}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      )}
    </div>
  );
}

export default LanguageSelect;
