import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "../App.css";
import "./mainpage.css";
import ModeToggle from "../components/header/ModeToggle";
import { isMobile } from "react-device-detect";
import HeaderMobile from "../components/header-mobile/HeaderMobile";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../store/reducers/layoutSlice";
import VideoText from "../components/mainpage/VideoText";
import { useTheme } from "@emotion/react";
import SmokeBg from "../components/mainpage/SmokeBg";
import WhatWeOffer from "../components/mainpage/what-we-offer/WhatWeOffer";
import { useState } from "react";
import Technologies from "../components/mainpage/tehnologies/Tehnologies";

function Mainpage() {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const [isPlaying, setIsPlaying] = useState(true);
  const togglePlay = () => setIsPlaying((prevState) => !prevState);

  return (
    <div
      className="App"
      style={{ backgroundColor: theme.palette.primary.main }}
    >


      <div className="video-container">
        <SmokeBg isPlaying={isPlaying}/>
        <VideoText isPlaying={isPlaying} togglePlay={togglePlay} />
      </div>
      <WhatWeOffer />

      <Footer />
    </div>
  );
}

export default Mainpage;
