import React from "react";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import "./custom-button.css"; // Separate CSS for button styling
import { useTheme } from "@emotion/react";

function CustomButton({
  icon,
  isCarousel,
  onClick,
  carouselLabel,
  gridLabel,
  isSmallScreen,
}) {
  const theme = useTheme();
  return (
    <button
      className="toggle-layout-button"
      onClick={onClick}
      style={{

        backgroundColor: theme.palette.lightblue.main,
        color: theme.palette.text.secondary,
      }}
    >
      {isCarousel & icon ? <ViewModuleIcon /> : <ViewCarouselIcon />}
      {isCarousel ? gridLabel : carouselLabel}
    </button>
  );
}

export default CustomButton;
