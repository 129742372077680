import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import webGLFluidEnhanced from "webgl-fluid-enhanced";
import { selectActiveTheme } from "../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import fluidVideo from "../../media/video-mobile/fluid.mp4";
import fluidDarkVideo from "../../media/video-mobile/fluid-dark.mp4";
import "./smokebg.css";
import { useTheme } from "@emotion/react";

const SmokeBg = ({ isPlaying }) => {
  const theme = useTheme();
  const canvasRef = useRef(null);
  const fluidSimulation = useRef(null); 
  const themeMode = useSelector(selectActiveTheme);
  const [videoSrc, setVideoSrc] = useState(fluidVideo);

  useEffect(() => {
    setVideoSrc(themeMode === "dark" ? fluidDarkVideo : fluidVideo);
  }, [themeMode]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas && isPlaying) {
      // Initialize webGLFluidEnhanced with custom settings and palette
      fluidSimulation.current = webGLFluidEnhanced.simulation(canvas, {
        BRIGHTNESS: 0.5,
        SIM_RESOLUTION: 64,
        DYE_RESOLUTION: 512,
        CAPTURE_RESOLUTION: 256,
        DENSITY_DISSIPATION: 0.4,
        VELOCITY_DISSIPATION: 0.02,
        PRESSURE: 0.2,
        PRESSURE_ITERATIONS: 10,
        CURL: 30,
        INITIAL: true,
        SPLAT_AMOUNT: 2,
        SPLAT_RADIUS: 0.18,
        SPLAT_FORCE: 4000,
        SPLAT_KEY: "Space",
        SHADING: true,
        COLORFUL: true,
        COLOR_PALETTE: [
          "#6ae7da",
          "#55d1c6",
          "#42bdb2",
          "#2c5e54",
          "#1b4332",
          "#0b2d20",
          "#0f3b3b",
          "#0d2626",
          "#15252d",
          "#0c1a1a",
          "#ffffff",
        ],
        HOVER: true,
        BACK_COLOR: themeMode === "dark" ? "#2A6358" : "#FFFFFF",
        TRANSPARENT: false,
        BLOOM: true,
        BLOOM_ITERATIONS: 6,
        BLOOM_RESOLUTION: 256,
        BLOOM_INTENSITY: 0.6,
        BLOOM_THRESHOLD: 0.6,
        BLOOM_SOFT_KNEE: 0.7,
        SUNRAYS: true,
        SUNRAYS_RESOLUTION: 196,
        SUNRAYS_WEIGHT: 1.0,
      });
    }

    // Cleanup on unmount or when isPlaying is toggled off
    return () => {
      if (fluidSimulation.current) {
        // Check if simulation exists before trying to destroy
        fluidSimulation.current = null; // Set to null for cleanup
      }
    };
  }, [themeMode, isPlaying]); // Re-run effect whenever isPlaying changes



  return (
    <div
      className="smoke-container"
      style={{
        height: isMobile ? "80vh" : "95vh",
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      {isPlaying && (
        <canvas
          ref={canvasRef}
          style={{ width: "100%", minHeight: "100vh" }}
        ></canvas>
      )}
    </div>
  );
};

export default SmokeBg;
