import "./what-we-offer-card.css";
import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";

function ServiceCard({
  icon,
  title,
  description,
  isCarousel,
  isSmallScreen,
  animationDelay,
  animateSlide,
}) {
  const theme = useTheme();
  const { HeadingSmaller, ParagraphSmaller } = useFontSizes();
  const { ref: cardRef, inView: cardInView } = useIntersectionObserver();
  const [isVisible, setIsVisible] = useState(false); 

  useEffect(() => {
    if (cardInView && !isVisible) { 
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [cardInView, isVisible]);

  return (
    <div
      ref={cardRef}
      className={`card-container ${isVisible ? "visible" : ""} ${animateSlide ? "slide-animation" : ""}`}
      style={{
        "--box-shadow-color": theme.palette.hover.boxShadow,
        "--animation-delay": `${animationDelay}s`,
        flex: isCarousel
          ? isSmallScreen
            ? "0 0 43.5%"
            : "0 0 28.5%"
          : isSmallScreen
          ? "0 0 80%"
          : "0 0 40%",
        fontSize: HeadingSmaller,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.mainTransparent,
        padding: isCarousel && isSmallScreen ? "8px" : "20px",
      }}
    >
      <div className="card-icon">{icon}</div>
      <h3
        className="card-title"
        style={{ fontSize: HeadingSmaller  }}
      >
        {title}
      </h3>
      <p className="card-description" style={{ fontSize: ParagraphSmaller,
        color: theme.palette.text.third,
       }}>
        {description}
      </p>
    </div>
  );
}

export default ServiceCard;
