import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

export const THEME_MODES = {
  LIGHT: "light",
  DARK: "dark",
};

export const LANGUAGE_OPTIONS = {
  ENGLISH: "en",
  TURKISH: "tr",
  SERBIAN: "sr",
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    language: LANGUAGE_OPTIONS.ENGLISH,
    theme: THEME_MODES.LIGHT,
  },
  reducers: {
    setThemeMode(state, action) {
      state.theme = state.theme === THEME_MODES.LIGHT ? THEME_MODES.DARK : THEME_MODES.LIGHT;
    },
    setLanguage(state, action) {
      const { language } = action.payload;
      if (Object.values(LANGUAGE_OPTIONS).includes(language)) {
        state.language = language;
        i18next.changeLanguage(language); 
      }
    },
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const { setThemeMode, setLanguage } = userSlice.actions;

export const selectActiveTheme = (state) => state.user.theme;
export const selectActiveLanguage = (state) => state.user.language;

export default userSlice.reducer;
